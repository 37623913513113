import React from "react";

import ContactForm from "../../../../ContactForm";

const PelletsContact = ({ name = "pel" }) => {
    return (
        <ContactForm
            phoneHeadline="0 42 62 – 79 99 79"
            phoneLink="+494262799961"
            emailHeadline="pelletsonline@hoyer.de"
            urlEnding="pellets"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            scrollToAnchor="contact"
        />
    );
};

export default PelletsContact;
